.Teams {
    width: 1040px;
    height: 117px;
    display: flex;
    flex-direction: row;
}

.NoTeams {
    width: 1040px;
    height: 117px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
}

.YellowTriangle {
    width: 10%;
    stroke-width: 60;
    fill: var(--mf-yellow-color);
    stroke: var(--mf-yellow-color);
}

.YellowTriangle .Star {
    stroke-width: 3;
    fill: #000000;
    stroke: #000000;
}

.BlackTriangle {
    width: 10%;
    stroke-width: 60;
    fill: var(--mf-black-color);
    stroke: var(--mf-black-color);
}

.BlackTriangle .Star {
    stroke-width: 3;
    fill: #FFFFFF;
    stroke: #FFFFFF;
}

.LeftTeam {
    width: 366px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.LeftTeamContent {
    width: 90%;
    height: 100%;
    text-align: right;
    font-size: 29px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.LeftTeamNames {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    margin-right: 9px;
}

.RightTeam {
    width: 366px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.RightTeamContent {
    width: 90%;
    height: 100%;
    text-align: left;
    font-size: 29px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.RightTeamNames {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-left: 9px;
}

.PlayerName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 320px;
}