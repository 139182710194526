.MFStatsTable {
    margin-left: auto;
    margin-right: auto;
}

.MFStatsEmpty {
    text-align: center;
    font-size: 35px;
}

.Header {
    text-align: center;
    font-size: 50px;
    padding-top: 25px;
    padding-bottom: 5px;
}

.Title {
    width: 24%;
    background-color: var(--mf-red-color);
    color: var(--font-white-color);
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.Yellow {
    width: 38%;
    background-color: var(--mf-yellow-color);
    color: var(--font-black-color);
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    white-space: pre;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.Black {
    width: 38%;
    background-color: var(--mf-black-color);
    color: var(--font-white-color);
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    white-space: pre;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.TopLeft {
    border-top-left-radius: 15px;
}

.TopRight {
    border-top-right-radius: 15px;
}

.BottomLeft {
    border-bottom-left-radius: 15px;
}

.BottomRight {
    border-bottom-right-radius: 15px;
}

table,
td {
    padding: 10px;
    margin: 0px;
    border: none;
    border-collapse: collapse;
}