.MFOverlay {
    width: 1920px;
    height: 1080px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.MFOverlay.MF {
    font-family: var(--mf-font-family);
}

.MFOverlay.WFT {
    font-family: var(--wft-font-family);
}